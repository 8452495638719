import React from 'react'
import CountdownLanding from './CountdownLanding'
import CustomerTestimonials from './CustomerTestimonials'
import FAQ from '../../views/Faq/Faq'

function PromotionPage() {
  return (
    <>
      <CustomerTestimonials />
      <FAQ />
      <CountdownLanding />
    </>
  )
}

export default PromotionPage