import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';
import blogs from './blogs.json';
import blogLogo from '../../assets/new/light_logo.png';
import blog1 from '../../assets/new/blog/blog1.png';
import blog2 from '../../assets/new/blog/blog2.png';
import blog3 from '../../assets/new/blog/blog3.png';
import blog4 from '../../assets/new/blog/blog4.png';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

const BottomTitle = styled('div')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 32,
  lineHeight: '48px',
  color: '#6B6B6B',
  marginBottom: '20px !important',
  [theme.breakpoints.down('sm')]: {
    fontSize: 24,
    lineHeight: '24px',
  },
}));

const BlogItem = () => {
  const { id } = useParams();

  const heroStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${id === "1" ? blog1 : id === "2" ? blog2 : id === "3" ? blog3 : blog4})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '70vh',
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%)',
    zIndex: 1,
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <Box style={heroStyles} className="relative">
        <Box style={overlayStyle} className="transition-opacity duration-300 hover:opacity-75" />
        <Box className="relative z-10 text-center px-4 sm:px-6 lg:px-8 max-w-5xl mx-auto">
          <div className="flex items-center justify-center space-x-4 mb-8 animate-fade-in">
            <img src={blogLogo} alt="LockTheLoad logo" className="h-12 sm:h-16" />

          </div>
          <Typography
            variant="h3"
            component="h1"
            className="text-white text-3xl sm:text-4xl md:text-5xl font-bold mb-6 leading-tight animate-slide-up"
          >
            {blogs[id - 1].title}
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className="text-gray-200 text-lg sm:text-xl animate-fade-in"
          >
            {blogs[id - 1].publish_time}
          </Typography>
        </Box>
      </Box>

      {/* Content Section */}
      <Container className="relative -mt-16 z-20">
        <Box className="bg-white rounded-2xl shadow-xl p-6 sm:p-8 md:p-12 mb-12">
          <div className="prose prose-lg max-w-none">
            {blogs[id - 1].texts.map((text, index) => (
              <Typography
                key={index}
                variant="body1"
                component="p"
                className="text-gray-700 leading-relaxed mb-6 text-lg"
              >
                {text}
              </Typography>
            ))}
          </div>
        </Box>

        {/* Related Posts Section */}
        <div className="mb-16">
          <Typography
            className="text-3xl font-semibold text-gray-700 mb-8"
            sx={{
              fontSize: { xs: '24px', sm: '32px' },
              lineHeight: { xs: '32px', sm: '48px' },
            }}
          >
            Related Posts
          </Typography>

          <Grid container spacing={4} className="relative">
            {blogs.map(blog => {
              if (blog.id.toString() !== id) {
                return (
                  <Grid item xs={12} sm={6} md={4} key={blog.id}>
                    <NavLink
                      to={`/blog/${blog.id}`}
                      onClick={() => window.scrollTo(0, 0)}
                      className="block group"
                    >
                      <div className="bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-2xl">
                        <div className="aspect-w-16 aspect-h-9 overflow-hidden">
                          <img
                            src={blog.id === 1 ? blog1 : blog.id === 2 ? blog2 : blog.id === 3 ? blog3 : blog4}
                            alt={blog.title}
                            className="w-full h-48 object-cover transform transition-transform duration-300 group-hover:scale-105"
                          />
                        </div>
                        <div className="p-6">
                          <Typography
                            variant="body1"
                            className="text-gray-800 font-medium line-clamp-2 group-hover:text-blue-600 transition-colors"
                          >
                            {blog.bottom_text}
                          </Typography>
                        </div>
                      </div>
                    </NavLink>
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
        </div>
      </Container>

      <style jsx>{`
        @keyframes fade-in {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        
        @keyframes slide-up {
          from { transform: translateY(20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }

        .animate-fade-in {
          animation: fade-in 0.6s ease-out;
        }

        .animate-slide-up {
          animation: slide-up 0.8s ease-out;
        }
      `}</style>
    </div>
  );
};

export default BlogItem;