
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import "./privacy.scss"
export const PrivacyPolicy = () => {
  useEffect(() => {
    document.title =
      "Privacy Policy - LockTheLoad - Loads Booking Manager";
  }, []);
  return (
    <>
      <div className='privacy ' >
        <Box>
          <div className="scroll_box">
            <div className="page-body">
              <section className="page-head" >
                <Typography variant='h2'>
                  Welcome to LockTheLoad.
                </Typography>
                <Typography variant='h6'>
                  LockTheLoad is owned and operated by Primer IT Solutions Inc. (Hereinafter referred to as
                  <b>"LockTheLoad"</b>).
                </Typography>
              </section>
              <Typography variant='h4' component="h1">
                Privacy Policy - LockTheLoad
              </Typography>
              <span>Published: May 22, 2023</span>
              <Typography variant='h6'>
                LockTheLoad values your privacy and the protection of your personal data. This privacy policy describes what information we collect from you, how we collect it, how we use it, how we obtain your consent, how long we keep it in our data- bases and, if necessary, with whom we share it.
              </Typography>
              <Typography variant='h6'>
                By using the platform, you are accepting the practices described in this privacy policy. Your use of the platform is also subject to our terms and conditions. In this privacy policy, the words "platform" refers to the LockTheLoad Google Chrome extension and the website as a whole, "we", "us", "our" and "LockTh- eLoad" refers to LockTheLoad and "you" and "user" refers to you, the user of LockTheLoad.
              </Typography>
              <Typography variant='h6'>This privacy policy may change from time to time. Your continued use of the platform after we make changes to this privacy policy is deemed acceptance of those changes, so please check this policy periodically for updates. This privacy policy has been developed and is maintained in accordance with all applicable federal and international laws and regulations and, in particular, the California Consumer Privacy Act (CCPA), the Virginia Consumer Data Protection Act (VCDPA) and the GDPR (General Data Protection Regulation - European regula- tions).
              </Typography>
              <Typography variant='h6'>The App’s use and transfer of information received from Google APIs to any other app will adhere to <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "blue" }}
              >Google API Services User Data Policy</a
              >, including the Limited Use requirements.</Typography>
              <Typography variant='h6'> Notwithstanding anything else in this Privacy Policy, if you provide the App access to the following types of your Google data, the App’s use of that data will be subject to these additional restrictions:</Typography>
              <Typography variant='h6'>
                <ul>
                  <li>The App will only use access to read, write, modify or control Gmail message bodies (including attachments), metadata, headers, and settings to provide a web email client that allows users to compose, send, read, and process emails and will not transfer this Gmail data to others unless doing so is necessary to provide and improve these features, comply with applicable law, or as part of a merger, acquisition, or sale of assets.</li>
                  <li>The App will not use this Gmail data for serving advertisements.</li>
                  <li>The App will not allow humans to read this data unless we have your affirmative agreement for specific messages, doing so is necessary for security purposes such as investigating abuse, to comply with applicable law, or for the App’s internal operations and even then only when the data have been aggregated and anonymized.
                  </li>
                </ul>
              </Typography>
              <Typography variant='h4'>
                1. GENERAL INFORMATION
              </Typography>
              <Typography variant='h6'>
                The personal data of users that are collected and processed through:

                <ul>
                  <li><a target="_blank"
                    rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/locktheload-loads-booking/abfpabcljnjfonkbepfodpdembndjjnk">LockTheLoad Google Chrome extension. </a></li>
                  <li><a target="_blank"
                    rel="noopener noreferrer" href="https://locktheload.com">https://locktheload.com</a></li>
                </ul>
              </Typography>
              <Typography variant='h6'>
                Will be under the responsibility and in charge of:
              </Typography>
              <Typography variant='h6'>
                <ul>
                  <li>Primer IT Solutions Inc.</li>
                </ul>
              </Typography>
              <Typography variant='h4'>
                2. HOW DO YOU OBTAIN MY CONSENT?
              </Typography>
              <Typography variant='body1'>
                By downloading and using the platform, registering as a user, using the func- tionalities available on the platform, contacting us through our contact informa- tion, and providing us with personal information to communicate with you, you consent to our collection, storage and use of your information on the terms contained in this privacy policy. You may withdraw your consent at any time by sending us your request through our contact information.
              </Typography>
              <Typography variant='h4'>
                3. TYPES OF INFORMATION COLLECTED
              </Typography>
              <Typography variant='body1'>
                The information we collect from our users helps us to continually improve the user experience on the platform. These are the types of information we collect:
              </Typography>
              <Typography variant='span'>
                Information you provide to us.</Typography>
              <Typography variant='body1'>
                You provide information when you download and install the platform, purchase a subscription, use the features available on the platform, subscribe to our newsletter and/or communicate with us through our contact information or contact forms. As a result of those actions, you may provide us with the following information:          </Typography>
              <Typography variant='h6'>
                <ul>
                  <li>Name</li>
                  <li>E-mail address</li>
                  <li>
                    Any additional information related to you that you provide to us directly or
                    indirectly through our platform.
                  </li>
                </ul>
              </Typography>
              <Typography variant='h6'>
                LockTheLoad will not collect any personally identifiable information about you unless you provide it.
              </Typography>
              <Typography variant='span'>

                Information Collected Automatically:</Typography>
              <Typography variant='body1'>
                By accessing and using the platform, you automatically provide us with the following information:   </Typography>
              <Typography variant='h6'>
                <ul>
                  <li>Your IP address</li>
                  <li>Referrer URLs</li>
                </ul>
              </Typography>
              <Typography variant='span'>
                User data:</Typography>
              <Typography variant='body1'>
                User data generated through the use of the platform will be stored in the platform cache on the user's device. LockTheLoad does not store user data generated by the user on the platform through its use.
              </Typography>
              <Typography variant='span'>
                Payment Information:</Typography>
              <Typography variant='body1'>
                Your payment details will be processed by the pay- ment processors available on this website (Stripe), which will treat and store your data securely and for the sole purpose of processing the purchase of sub- scriptions. LockTheLoad reserves the right to engage any payment platform available on the market, which processes your data for the sole purpose of pro- cessing the purchase of subscriptions.  </Typography>
              <Typography variant='h6'>
                View Stripe's privacy policy here:
              </Typography>
              <Typography variant='h6'>
                <ul>
                  <li>
                    <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>
                  </li>
                </ul>
              </Typography>
              <Typography variant='b'>
                Social Media:</Typography>
              <Typography variant='body1'>
                On our website you will find links and functions linked to differ- ent social networks, in which you can share your information. It is advisable to consult the privacy policy and data protection of each social network used on our website.
              </Typography>
              <Typography variant='h6'>
                <ul>
                  <li>
                    Facebook: <a href="https://www.facebook.com/privacy/explanation"
                    >https://www.facebook.com/privacy/explanation</a
                    >
                  </li>
                  <li>
                    Instagram: <a href="http://instagram.com/about/legal/privacy"
                    >http://instagram.com/about/legal/privacy</a
                    >/
                  </li>
                  <li>
                    YouTube: <a href="https://policies.google.com/privacy"
                    >https://policies.google.com/privacy</a
                    >
                  </li>
                  <li>
                    Telegram: <a href="https://telegram.org/privacy">https://telegram.org/privacy</a>
                  </li>
                </ul>
              </Typography>
              <Typography variant='b'>
                Contact information: </Typography>
              <Typography variant='body1'>
                We may access some personal information about the user, such as name and email address, when the user or any third party com- municates with us through our contact information. Personal information pro- vided through our contact information is not stored on any LockTheLoad server and will be stored on the respective server of our email service.
              </Typography>
              <Typography variant='h4'>
                4. HOW LONG WE KEEP YOUR DATA
              </Typography>
              <Typography variant='h6'>
                Personal data provided by users through the use of the platform will be kept for the time necessary to provide the functionalities available on the platform or until the user decides to cancel the paid subscription or decides to unsubscribe from our newsletter or until the user requests the deletion of his data. LockTh- eLoad may retain personal data for a longer period provided that the user has consented to such processing, as long as such consent is not withdrawn. In ad- dition, LockTheLoad may be obliged to retain personal data for a longer period provided that this is required for compliance with a legal obligation or by order of an authority. Once the retention period has expired, the personal data will be deleted. Therefore, the right of access, the right of deletion, the right of rec- tification and the right to data portability cannot be asserted after the retention period has expired.
              </Typography>
              <Typography variant='h4'>
                5. HOW WE USE YOUR INFORMATION.
              </Typography>
              <Typography variant='h6'>
                In general, we use the information we collect primarily to provide, maintain, protect and improve our current platform and services. We use personal information collected through our platform as described below:
              </Typography>
              <Typography variant='h6'>
                <ul>
                  <li>Provide the Google Chrome extension.</li>
                  <li>Provide the free trials.</li>
                  <li>Provide the functionalities available on the platform.</li>
                  <li>Provide the subscriptions.</li>
                  <li>Understand and improve your experience using our platform.</li>
                  <li>Respond to your comments or questions through our contact information.</li>
                  <li>
                    Send you related information, including confirmations, invoices, technical
                    notices, updates, security alerts, and support and administrative messages.
                  </li>
                  <li>
                    Communicate with you about upcoming events, offers and news about content,
                    products and services offered by LockTheLoad and our partners.
                  </li>
                  <li>LockTheLoad marketing purposes.</li>
                  <li>
                    Link or combine your information with other data we obtain from third parties to
                    help us understand your needs and provide you with better service.
                  </li>
                  <li>
                    Protect, investigate and deter fraudulent, unauthorized or illegal activities.
                  </li>
                </ul>
              </Typography>
              <Typography variant='h4'>
                6. DATA SHARING POLICY
              </Typography>
              <Typography variant='h6'>
                The provisions of this policy apply to LockTheLoad's data sharing processes with governmental entities, private entities or individuals, regardless of the source, form or nature of this data.

              </Typography>
              <Typography variant='h6'>
                This policy does not apply if the entity requesting the data is a government entity and the request is for security purposes or to meet judicial requirements.

              </Typography>
              <Typography variant='h6'>
                This policy conforms to international personal data protection policies in a manner that does not conflict with them and with applicable regulations and amendments issued in this regard.

              </Typography>
              <Typography variant='b'>
                Key principles of data sharing:
              </Typography>
              <Typography variant='body1'>
                LockTheLoad relies on the following principles for data sharing:

              </Typography>
              <Typography variant='b'>
                Lawfulness of purpose: </Typography>
              <Typography variant='body1'>
                Data will be shared for lawful purposes based on a legal basis or justified practical necessity aimed at achieving a public interest without harming national interests, the activities of entities, the privacy of individuals or the safety of the environment - with the exception of data and entities that are protected by law. </Typography>
              <Typography variant='b'>
                Authorized access:    </Typography>
              <Typography variant='body1'>
                All parties involved in data sharing shall be authorized to view, obtain and use such data (which may require security scanning depending on the nature and sensitivity of the data), in addition to having the knowledge, skill and appropriately qualified and trained persons to handle the shared data.  </Typography>
              <Typography variant='b'>
                Transparency: </Typography>
              <Typography variant='body1'>
                All parties involved in data sharing processes shall make available all information necessary for data sharing, including: the data required, its purpose of collection, means of transfer, storage methods, controls used for protection and disposal mechanism.  </Typography>
              <Typography variant='b'>
                Joint responsibility: </Typography>
              <Typography variant='body1'>
                All parties involved in data sharing shall have joint responsibility for decisions regarding data sharing and the processing of data for their specific purposes. They shall also be jointly responsible for ensuring that the security standards stipulated in the data sharing agreement, as well as other related regulations, legislation and policies, are applied.</Typography>
              <Typography variant='b'>
                Data security: </Typography>
              <Typography variant='body1'>
                All parties involved in data sharing shall apply appropriate security standards to protect and share data in a secure environment in accordance with related regulations and legislation, and in accordance with data pro- tection regulations.</Typography>
              <Typography variant='b'>
                Ethical use:  </Typography>
              <Typography variant='body1'>
                All parties involved in data sharing shall abide by ethics during the data sharing process, to ensure the use of data not only within the framework of data security policies or other related regulatory and legislative requirements, but also within the framework of fairness, integrity, sincerity and respect.</Typography>
              <Typography variant='h4'>
                7. HOW WE SHARE INFORMATION
              </Typography>
              <Typography variant='h6'>
                Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as described below.
              </Typography>
              <Typography variant='b'>
                Google Services:   </Typography>
              <Typography variant='body1'>
                We use certain Google services to host our platform and allow users to download and install the platform on the Google Chrome browser. By downloading, installing and using our platform, the user may share certain data through Google services.</Typography>
              <Typography variant='h6'>
                Please see Google's privacy policy here.
              </Typography>
              <Typography variant='h6'>
                <ul>
                  <li>
                    <a href="https://policies.google.com/privacy"
                    >https://policies.google.com/privacy
                    </a>
                  </li>
                </ul>
              </Typography>
              <Typography variant='b'>
                Commercial Load Boards:   </Typography>
              <Typography variant='body1'>
                through the use of the platform, LockTheLoad will be able to access the user's commercial load board and post on the user's behalf, including, but not limited to, searching the load boards, collecting load information, sending communications to intermediaries, making phone calls and other functions. The information provided by the user through the commercial load boards may be shared with load platform providers. Some of these load board providers with whom we may share your information include the following:
              </Typography>
              <Typography variant='h6'>
                <ul>
                  <li>DAT</li>
                  <li>Truckstop</li>
                  <li>Uber Freight</li>
                  <li>Coyote Logistics</li>
                  <li>Schneider</li>
                </ul>
              </Typography>
              <p>
                <b>Third-Party Service Providers.</b> We use third-party services to perform functions
                on our website. Examples include: creating and hosting the website, processing payments
                (Stripe), sending emails, analyzing data, creating ads, providing marketing services
                and providing search results.
              </p>
              <p>
                These third-party services and tools may have access to personal information needed
                to perform their functions, but may not use that information for other purposes.
                Information shared with these third-party services will be treated and stored in
                accordance with their respective privacy policies and our privacy policy. Please
                refer to the privacy policy of these third-party services.
              </p>
              <p>
                <b>Email marketing: </b>
                We use the information provided by users to conduct mar- keting campaigns and send relevant
                information about our products and ser- vices. By registering as a user on the platform,
                you authorize us to use your in- formation for email marketing. We will use third-party
                services and platform to perform email marketing, so we may share certain information
                with some of these third parties for the sole and exclusive purpose of sending our emails.
              </p>
              <p>
                <b>Business Transfers.</b> In the event LockTheLoad creates, merges with, or is acquired
                by another entity, your information will likely be transferred. LockTeLoad will send
                you an email or post a prominent notice on our platform before your information becomes
                subject to another privacy policy.
              </p>
              <p>
                <b>Protection of LockTheLoad and Others.</b>
                We release personal information when we believe release is appropriate to comply with
                the law, enforce or apply our terms and conditions and other agreements, or protect the
                rights, property, or safety of LockTheLoad, our users, or others. This includes exchanging
                information with other companies and organizations for fraud protection and credit risk
                reduction.
              </p>
              <p>
                <b>Anonymous Information.</b> LockTheLoad uses anonymous browsing information collected
                automatically by our servers primarily to help us administer and improve the platform.
                We may also use aggregated anonymous information to provide information about the Platform
                to potential business partners and other unaffiliated entities. This information is not
                personally identifiable.
              </p>
              <Typography variant='h4'>8. PROTECTION OF YOUR INFORMATION </Typography>
              <p>
                We restrict authorized access to your personal information to those persons who have
                a legitimate need to know that information and to those persons you have authorized
                to have access to that information. LockTheLoad follows generally accepted industry
                standards to protect the personal information submitted to us, both during
                transmission and once LockTheLoad receives it. No method of transmission over the
                Internet, or method of electronic storage, is 100% secure. Therefore, while
                LockTheLoad strives to use commercially acceptable means to protect your personal
                information, we cannot guarantee its absolute security. We will not sell, distribute
                or lease your personal information to third parties unless we have your permission
                or are required by law to do so.
              </p>
              <Typography variant='h4'>9. RIGHTS </Typography>
              <p>
                Users who provide information through our platform, as data subjects, have the right
                to access, rectify, download or delete their information, as well as to restrict and
                oppose certain processing of their information. While some of these rights apply
                generally, others only apply in certain limited circumstances. These rights are
                described below:
              </p>
              <b>Access and portability:</b> to access and know what information is stored on our
              servers, you can send us your request through our contact information.
              <b>Rectification, restriction, limitation and deletion: </b> You may also rectify,
              restrict, limit or delete much of your information.
              <b>Right to be informed:</b> Users of our platform will be informed, upon request,
              about what data we collect, how it is used, how long it is kept and whether it is shared
              with third parties
              <b>Object:</b> Where we process your data based on our legitimate interests, as explained
              above, or in the public interest, you may object to this processing in certain circumstances.
              In such cases, we will stop processing your information unless we have legitimate grounds
              to continue processing it or where necessary for legal reasons
              <b>Withdraw consent:</b> Where you have previously given your consent, for example
              to allow us to process and store your personal information, you have the right to withdraw
              your consent to the processing and storage of your information at any time. For example,
              you can withdraw your consent by updating your settings. In certain cases, we may continue
              to process your information after you have withdrawn your consent if we have a lawful
              basis for doing so or if your withdrawal of consent was limited to certain processing
              activities.
              <b>Complaint:</b> If you wish to lodge a complaint about our use of your information
              (and without prejudice to any other rights you may have), you have the right to do
              so with your local supervisory authority. Users may exercise all of these rights by
              contacting us via the contact information or contact page.
              <b>Rights related to automated decision-making, including profiling:</b> Users may
              request that we provide them with a copy of the automated processing activities we
              conduct if they believe that data is being unlawfully processed.
              <p>
                Users or owners of the personal data they provide through the platform can exercise
                these rights over their personal data at any time and without any limitation by
                sending us their request through our contact information.
              </p>
              <Typography variant='h4'>10. PROTECTION OF CHILDREN'S ONLINE PRIVACY </Typography>
              <p>
                We comply with the requirements of national and international data protection
                regulations regarding the protection of personal data of minors. We do not collect
                any personal information from children under the age of 13. If we become aware that
                a minor has provided us with personal information, we will take immediate steps to
                delete such information.
              </p>
              <Typography variant='h4'>11. THIRD PARTIES</Typography>
              <p>
                Except as expressly included in this privacy policy, this document addresses only
                the use and disclosure of information LockTheLoad collects from you. If you disclose
                your information to others, whether other users or LockTheLoad vendors, different
                rules may apply to their use or disclosure of the information you disclose to them.
                LockTheLoad does not control the privacy policies of third parties, and you are
                subject to the privacy policies of those third parties where applicable. LockTheLoad
                is not responsible for the privacy or security practices of other Internet platform,
                including those linked to or from the LockTheLoad platform. LockTheLoad encourages
                you to ask questions before disclosing your personal information to others.
              </p>
              <Typography variant='h4'> 12. CONTACT </Typography>
              <p>
                If you have questions or concerns about this privacy policy and the treatment and
                security of your data, please contact us using the contact information below:
              </p>
              <b>Primer IT Solutions Inc - LockTheLoad. </b>
              <p>
                <a href="mailto:admin@locktheload.com">admin@locktheload.com</a>
              </p>

              <section id="cookie-policy" className="pt-4">
                <h2 className="font-semibold text-2xl text-primary">Cookie Policy - LockTheLoad</h2>
                <p className="text-lg text-secondary pb-4">Published: May 22, 2023</p>
                <div>
                  <p>
                    This cookie policy explains how and why cookies and other similar technologies may be
                    stored on and accessed from your device when you use or visit:
                  </p>
                  <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/locktheload-loads-booking/abfpabcljnjfonkbepfodpdembndjjnk">LockTheLoad Google Chrome extension. </a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://locktheload.com">https://locktheload.com</a></li>
                  </ul>
                  <p>
                    This cookie policy should be read together with our privacy policy and our terms and
                    conditions.
                  </p>
                  <p>
                    By using the website, you accept the use of cookies by LockTheLoad, in the terms
                    contained in this policy.
                  </p>
                  <ul>
                    <li>
                      <b>1. WHAT ARE COOKIES?</b>
                      <p>
                        Cookies are small text files that are stored on your computer or mobile device
                        when you visit a website. They allow the website to recognize your device and
                        remember if you have been to the website before. Cookies are a very common web
                        technology; most websites use cookies and have done so for years. Cookies are
                        widely used to make the website work more efficiently. Cookies are used to measure
                        which parts of the website users visit and to personalize their experience.
                        Cookies also provide information that helps us monitor and improve the performance
                        of the website.
                      </p>
                    </li>
                    <li>
                      <b>2. COOKIE SETTINGS</b>
                      <p>
                        If you do not want cookies to be dropped on your device, you can adjust the
                        setting of your Internet browser to reject the setting of all or some cookies and
                        to alert you when a cookie is placed on your device. For further information about
                        how to do so, please refer to your browser ‘help’, ‘tool’, or ‘edit’ section.
                        Please note that if you use your browser settings to block all cookies, including
                        strictly necessary cookies, you may not be able to access or use all or parts of
                        the functionalities of the website.
                      </p>
                      <p>
                        If you want to remove previously-stored cookies, you can manually delete the
                        cookies at any time. However, this will not prevent the website from placing
                        further cookies on your device unless and until you adjust your Internet browser
                        setting as described above.
                      </p>
                      <p>
                        We provide the links for the management and blocking of cookies depending on the
                        browser you use:
                      </p>
                      <ul className='browsers'>
                        <li>
                          <b>Microsoft Edge:</b>
                          <a
                            href="https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us"
                          >https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us</a
                          >
                        </li>

                        <li>
                          <b>Firefox:</b>
                          <a
                            href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                          >https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox</a
                          >
                        </li>

                        <li>
                          <b>Chrome:</b>
                          <a href="https://support.google.com/chrome/answer/95647?hl=en"
                          >https://support.google.com/chrome/answer/95647?hl=en</a
                          >
                        </li>
                        <li>
                          <b>Safari:</b>
                          <a
                            href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                          >https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a
                          >
                        </li>

                        <li>
                          <b>Opera:</b>
                          <a href="https://help.opera.com/en/latest/web-preferences/"
                          >https://help.opera.com/en/latest/web-preferences/</a
                          >
                        </li>
                        <p>
                          In cases where you access the website through an iOS or Android mobile de- vice,
                          please follow the instructions below to delete or block cookies on your de-
                          vice:
                        </p>
                        <li>
                          <b>Android:</b>
                          <a href="https://support.google.com/answer/32050"
                          >https://support.google.com/answer/32050</a
                          >
                        </li>
                        <li>
                          <b>iOS:</b>
                          <a href="https://support.apple.com/en-us/HT201265"
                          >https://support.apple.com/en-us/HT201265</a
                          >
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>3.COOKIES WE USE</b>
                      <p>We use the following cookies on our website:</p>
                      <table>
                        <thead>

                          <tr>
                            <th> Cookie </th>
                            <th>Domain</th>
                            <th>Description</th>
                            <th>Duration</th>
                            <th>Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> __gsas </td>
                            <td>.locktheload.com</td>
                            <td
                            >Google AdManager sets this cookie for advertising, including serving and
                              rendering ads, personalizing ads.</td
                            >
                            <td>1 year 24 days</td>
                            <td>Advertisement</td>
                          </tr>
                          <tr>
                            <td> NID </td>
                            <td>.google.com</td>
                            <td
                            >Google sets the cookie for ad- vertising purposes; to limit the number of
                              times the user sees an ad, to unwanted mute ads, and to measure the ef-
                              fectiveness of ads.</td
                            >
                            <td>6 months</td>
                            <td>Advertisement</td>
                          </tr>
                          <tr>
                            <td> CONSENT </td>
                            <td>.google.com</td>
                            <td
                            >YouTube sets this cookie via embedded YouTube videos and registers anonymous
                              sta- tistical data.</td
                            >
                            <td>2 years</td>
                            <td>Analytics</td>
                          </tr>
                        </tbody>
                      </table>
                    </li>
                    <li>
                      <b>4.PURPOSES OF OUR COOKIES</b>
                      <p>Our cookies are used for the following purposes:</p>
                      <p>
                        <b>Strictly Necessary:</b> These cookies are essential for the website to perform its
                        basic functions.
                      </p>
                      <p>
                        <b>Security: </b> We use these cookies to help identify and prevent potential security
                        risks.
                      </p>
                      <p>
                        <b>Third Party Cookies:</b>
                        Third party cookies may come from service providers that provide functional web services
                        or tools to LockTheLoad for the optimal functioning and operation of our platform.
                        We use third-party cookies responsibly and for the sole purpose of providing optimal
                        operation of the platform and services. The platform uses third party cookies to "publish"
                        user information (request sent to the server to update available loads) on behalf of
                        the user through the use of the commercial load board available on the platform.
                      </p>
                      <p>
                        <b>Performance:</b> Performance cookies collect information on how users interact with
                        our website, including what pages are visited most, as well as other ana- lytical data.
                        We use these details to improve how our website functions and to understand how users
                        interact with them.
                      </p>
                      <p>
                        <b>Advertisement:</b> These cookies are used to display relevant advertising to visi
                        tors using the website, as well as to understand and report on the effectiveness of
                        ads served on our website. Advertisement cookies track details such as the number of
                        unique visitors, the number of times specific advertisements have been shown and the
                        number of clicks the advertisements have received. These cookies are also used to create
                        user profiles, such as showing you ads based on content you have viewed on our website
                        or other websites. These cookies are set by the website and trusted third party networks
                        such as Google and are typically persistent in nature.
                      </p>
                      <p>
                        <b>Analytics.</b> We use Google Analytics as data analysis tools. These tools and technologies
                        collect and analyze certain types of information, such as IP ad- dresses, device and
                        software identifiers, referring and exit URLs, feature usage metrics and statistics,
                        media access control address (MAC address), unique mobile device identifiers and other
                        similar information through the use of cookies. We use GOOGLE Analytics data collection
                        to improve our platforms and services
                      </p>
                    </li>
                  </ul>
                </div>
              </section>
              <div className='mt-4'></div>
              <section id="terms-conditions" className="pt-4">
                <h2 className="font-semibold text-2xl text-primary">Terms and Conditions - LockTheLoad</h2>
                <p className="text-lg text-secondary pb-4">Published: May 22, 2023</p>
                <p>
                  By using the platform, you agree to be bound by these terms and conditions and our privacy
                  policy. In these terms and conditions, the words "platform" refers to the LockTheLoad
                  Google Chrome extension and the website as a whole, "we", "us", "our" and "LockTheLoad"
                  refers to LockTheLoad and "you" and "user" refers to you, the user of LockTheLoad.
                </p>
                <p>
                  The following terms and conditions apply to your use of the platform. This includes mobile
                  and tablet versions, as well as any other version of LockTheLoad accessible via desktop,
                  mobile, tablet, social media or other devices.
                </p>
                <p>
                  PLEASE READ THESE CONDITIONS CAREFULLY BEFORE DOWNLOADING AND USING THE FUNCTIONALITIES
                  AVAILABLE ON THE PLATFORM.
                </p>
                <ul>
                  <li>
                    <b>1. ACCEPTANCE OF TERMS </b>
                    <p>
                      By downloading, installing, and using the platform, you agree to be bound by this
                      agreement. If you do not accept the terms of this agreement, you should not use the
                      platform and discontinue use of the platform immediately. We may modify this agreement
                      from time to time, and such modification shall be effective upon its posting on the
                      platform. You agree to be bound by any modification to these terms and conditions when
                      you use LockTheLoad after any such modification is posted; it is therefore important
                      that you review this agreement regularly.
                    </p>
                    <p>
                      Use of the platform and services is prohibited for children under the age of 13. For
                      children under the age of 18 and over the age of 13, it is the responsibility of
                      parents and legal guardians to determine whether use of the platform or any of the
                      services available on the platform is appropriate for their child or ward.
                    </p>
                    <p>
                      By using the platform, you represent and warrant that you have the full right, power
                      and authority to enter into this agreement and to fully perform all of your
                      obligations hereunder. You further represent and warrant that you are under no legal
                      disability or contractual restriction that prevents you from entering into this
                      agreement.
                    </p>
                  </li>
                  <li>
                    <b> 2. NOTIFICATIONS AND NEWSLETTER </b>
                    <p>
                      By providing LockTheLoad with your email address and telephone number, you agree that
                      we may use your email address to send you important notifications and communications
                      about our services, news and special content. If you do not wish to receive these
                      emails, you may opt-out of receiving them by sending us your request through our
                      contact information or by using the "unsubscribe" option in the emails. Opting out may
                      prevent you from receiving emails regarding our services, important news and special
                      content.
                    </p>
                  </li>
                  <li>
                    <b> 3. SUBSCRIPTIONS </b>
                    <p>
                      The use of the platform is available through paid subscriptions. The user can request
                      a free trial before purchasing a subscription by providing an email address. Only one
                      free trial will be provided per email. When a user purchases a subscription,
                      LockTheLoad will send a confirmation email. This confirmation email will be produced
                      automatically so that the user will have confirmation of the payment and the start of
                      the subscription. If the user does not receive the email confirmation of the purchase
                      and the start of the subscription, it is possible that it has been sent to your spam
                      folder.
                    </p>
                    <p>
                      LockTheLoad may change or discontinue the availability of subscriptions at any time at
                      its sole discretion. If a subscription purchase is cancelled, the payment made for the
                      subscription will be refunded in the applicable billing period. This does not affect
                      your statutory rights.
                    </p>
                    <p>
                      Subscriptions include automatic recurring payments. You authorize LockTheLoad to renew
                      your subscription and to charge you periodically and progressively on each billing
                      date. The subscription billing date is the date on which you purchase the subscription
                      and make the first payment. On the applicable billing date, you will automatically be
                      charged the applicable subscription fee. The subscription will remain active until you
                      cancel it or we terminate it. You must cancel your subscription before it renews to
                      avoid the next billing period. We will bill you for the subscription billing fee in
                      the payment method you choose during registration and subscription purchase.
                    </p>
                    <p>
                      Subscriptions will automatically renew for an additional billing period unless
                      cancelled prior to the next billing period. Users may cancel the subscription through
                      the user account settings in the billing section or by submitting a cancellation
                      request through our contact information and the subscription will be cancelled for the
                      next billing period. If a subscription is cancelled, the user will be able to continue
                      to use the payment features of the subscription until the next billing period, at
                      which time the subscription and access to the payment features of the subscription
                      will be cancelled.
                    </p>
                  </li>
                  <li>
                    <b>4. PAYMENTS </b>
                    <p>Subscriptions can be paid by the following payment methods:</p>
                    <ul><li>Credit/debit card (Visa, Master, Discover, Amex, Diners, etc.)</li></ul>
                    <p>
                      Payments will be processed through our payment processor Stripe. The subscription
                      payment will be charged to your credit/debit card immediately upon completion of the
                      payment and registration process for the subscription or additional service request.
                      The subscription will be activated upon completion of the payment and registration
                      process and will be charged on each billing date automatically. Once the transaction
                      is processed, we will send an electronic receipt to the user's email address.
                    </p>
                    <p>
                      If you find any inconsistencies in your billing, please contact us via our contact
                      details or you can make a complaint via the customer service of the relevant payment
                      processor.
                    </p>
                    <p>
                      If your card is declined, you will receive an error message. No payment will be
                      charged to your card and no order will be processed. There may be a pending
                      transaction on your account until your card issuing bank withdraws the authorization.
                      This usually takes 2 to 5 working days. Your card may be declined for a number of
                      reasons, such as insufficient funds, AVS (Address Verification System) mismatch or you
                      have entered an incorrect security code.
                    </p>
                    <p>
                      If your payment is declined, you will need to provide an alternative payment method or
                      provide another card on which the payment can be charged and processed.
                    </p>
                    <p>
                      Your payment data will be processed and retained securely and for the sole purpose of
                      processing the purchase of subscriptions. LockTheLoad reserves the right to engage any
                      payment processor available on the market to process your data for the sole purpose of
                      processing the purchase of subscriptions.
                    </p>
                  </li>
                  <li>
                    <b>5. LICENSE TO USE THE PLATFORM </b>
                    <p>
                      By downloading and installing the platform, LockTheLoad grants you a personal,
                      worldwide, royalty-free, non-assignable, non-exclusive license to use the platform and
                      the features included in the platform. This license is for the sole purpose of
                      allowing you to use the features available on the platform as permitted by these
                      terms, including the truck management system and the following features:
                    </p>
                    <ul>
                      <li>Set and save your upload preferences with filter functions.</li>
                      <li>Creating and sending e-mails preloaded on the platform.</li>
                      <li>Searching for uploads according to specified criteria.</li>
                      <li>Profit calculator for real-time evaluation of fuel costs, tolls and RPM.</li>
                    </ul>
                    <p>
                      You may not copy, modify, distribute, sell or rent any part of our platform or the
                      included software, nor may you reverse engineer or attempt to extract the source code
                      of such software, unless such restrictions are prohibited by law, or you have our
                      written permission.
                    </p>
                    <p>
                      The user agrees not to use the platform and the services negligently, for fraudulent
                      purposes or in an unlawful manner. Likewise, the user agrees not to partake in any
                      conduct or action that could damage the image, interests, or rights of the LockTheLoad
                      platform or third parties.
                    </p>
                    <p>
                      LockTheLoad reserves the right to terminate your access immediately, with or without
                      notice, and without liability to you, if LockTheLoad believes that you have violated
                      any of these terms or interfered with the use of the platform by others.
                    </p>
                  </li>
                  <li>
                    <b>6. DISCLAIMER </b>
                    <p>
                      By using the platform and the functionalities available on the platform, you accept
                      personal responsibility for the use of the platform and any information processed
                      through the platform. You agree that LockTheLoad does not guarantee any outcome,
                      benefit or failure as a result of your use of our platform. You acknowledge and agree
                      that your ultimate success or failure to use the platform will be the result of your
                      particular situation and other circumstances beyond LockTheLoad's control.
                    </p>
                  </li>
                  <li>
                    <b>7. COPYRIGHT </b>
                    <p>
                      All materials on LockTheLoad, including, without limitation, names, logos, trademarks,
                      games, images, text, columns, graphics, videos, photographs, illustrations, software
                      and other elements are protected by copyrights, trademarks and/or other intellectual
                      property rights owned and controlled by LockTheLoad or by third parties that have
                      licensed or otherwise provided their material to the platform. You acknowledge and
                      agree that all Materials on LockTheLoad are made available for limited,
                      non-commercial, personal use only. Except as specifically provided herein. No material
                      may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or
                      distributed in any way, or otherwise used for any purpose, by any person or entity,
                      without LockTheLoad prior express written permission. You may not add, delete,
                      distort, or otherwise modify the material. Any unauthorized attempt to modify any
                      material, to defeat or circumvent any security features, or to utilize LockTheLoad or
                      any part of the material for any purpose other than its intended purposes is strictly
                      prohibited.
                    </p>
                  </li>
                  <li >
                    <b>8. COPYRIGHT INFRINGEMENT </b>
                    <p>
                      LockTheLoad will respond to all inquiries, complaints and claims regarding alleged
                      infringement for failure to comply with or violation of the provisions contained in
                      the Digital Millennium Copyright Act (DMCA). LockTheLoad respects the intellectual
                      property of others, and expects users to do the same. If you believe, in good faith,
                      that any material provided on or in connection with the platform infringes your
                      copyright or other intellectual property right, please send us your copyright
                      infringement request pursuant to Section 512 of the Digital Millennium Copyright Act
                      (DMCA), via our contact information, with the following information:
                    </p>
                    <p>
                      Identification of the intellectual property right that is allegedly infringed. All
                      relevant registration numbers or a statement regarding ownership of the work should
                      be included.
                    </p>
                    <p>
                      A statement that specifically identifies the location of the infringing material, in
                      sufficient detail so that LockTheLoad can find it on the platform.
                    </p>
                    <p>Your name, address, telephone number and email address.</p>
                    <p>
                      A statement by you that you have a good faith belief that the use of the allegedly
                      infringing material is not authorized by the copyright owner, or its agents, or by
                      law.
                    </p>
                    <p>
                      A statement by you, made under penalty of perjury, that the information in your
                      notification is accurate, and that you are the copyright owner or authorized to act
                      on its behalf.
                    </p>
                    <p>
                      An electronic or physical signature of the copyright owner or of the person
                      authorized to act on the copyright owner's behalf.
                    </p>
                  </li>
                  <li>
                    <b>9. PROHIBITED ACTIVITIES </b>
                    <p>
                      The content and information available on the platform (including, but not limited to,
                      data, information, text, music, sound, photos, graphics, video, maps, icons or other
                      material), as well as the infrastructure used to provide such content and information,
                      is proprietary to LockTheLoad or licensed to the LockTheLoad by third parties. For all
                      content other than your content, you agree not to otherwise modify, copy, distribute,
                      transmit, display, perform, reproduce, publish, license, create derivative works from,
                      transfer, or sell or re-sell any information, software or services obtained from or
                      through the platform. In addition, the following activities are prohibited:
                    </p>
                    <p>
                      Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license,
                      copy or otherwise exploit any content of the services, including but not limited to,
                      using any robot, spider, scraper or other automated means or any manual process for
                      any purpose not in accordance with this agreement or without our express written
                      permission.
                    </p>
                    <p>
                      Violate the restrictions in any robot exclusion headers on the services or bypass or
                      circumvent other measures employed to prevent or limit access to the services.
                    </p>
                    <li>
                      Take any action that imposes, or may impose, in our discretion, an unreasonable or
                      disproportionately large load on our infrastructure.
                    </li>
                    <li>
                      Deep-link to any portion of the services for any purpose without our express written
                      permission.
                    </li>
                    <li>
                      "Frame", "mirror" or otherwise incorporate any part of the platform into any other
                      platforms or service without our prior written authorization.
                    </li>
                    <p>
                      Attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse
                      engineer any software programs used by LockTheLoad in connection with the services.
                    </p>
                    <p>
                      Circumvent, disable or otherwise interfere with security-related features of the
                      platform or features that prevent or restrict use or copying of any content.
                    </p>
                  </li>
                  <li>
                    <b>10. DISCLAIMER OF WARRANTIES </b>
                    <p>
                      Because of the nature of the Internet LockTheLoad provides and maintains the platform
                      on an "as is", "as available" basis and makes no promise that use of the platform will
                      be uninterrupted or entirely error free. We are not responsible to you if we are
                      unable to provide our Internet services for any reason beyond our control.
                    </p>
                    <p>
                      Except as provided above we can give no other warranties, conditions or other terms,
                      express or implied, statutory or otherwise and all such terms are hereby excluded to
                      the maximum extent permitted by law.
                    </p>
                    <p>
                      You will be responsible for any breach of these terms by you and if you use the
                      platform in breach of these terms you will be liable to and will reimburse LockTheLoad
                      for any loss or damage caused as a result.
                    </p>
                    <p>
                      LockTheLoad shall not be liable for any amount for failure to perform any obligation
                      under this Agreement if such failure is due to the occurrence of any unforeseen event
                      beyond its reasonable control, including, without limitation, Internet outages,
                      communications outages, fire, flood, or any uncontrollable act of nature.
                    </p>

                    <p>
                      These terms do not affect your statutory rights as a consumer which are available to
                      you.
                    </p>
                    <p>
                      Subject as aforesaid, to the maximum extent permitted by law, LockTheLoad excludes
                      liability for any loss or damage of any kind howsoever arising, including without
                      limitation any direct, indirect or consequential loss whether or not such arises out
                      of any problem you notify to LockTheLoad and LockTheLoad shall have no liability to
                      pay any money by way of compensation, including without limitation all liability in
                      relation to:
                    </p>
                    <p>Any incorrect or inaccurate information on the platform.</p>
                    <p>
                      The infringement by any person of any Intellectual Property Rights of any third
                      party caused by their use of the platform.
                    </p>
                    <p>
                      Any loss or damage resulting from your use or the inability to use the platform or
                      resulting from unauthorized access to, or alteration of your transmissions or data
                      in circumstances which are beyond our control.
                    </p>
                    <p>
                      Any loss of profit, wasted expenditure, corruption or destruction of data or any
                      other loss which does not directly result from something we have done wrong.
                    </p>
                    <p>
                      Any amount or kind of loss or damage due to viruses or other malicious software that
                      may infect a user's computer equipment, software, data or other property caused by
                      persons accessing or using content from the platform or from transmissions via
                      emails or attachments received from LockTheLoad.
                    </p>
                    <p>
                      All representations, warranties, conditions and other terms which but for this
                      notice would have effect.
                    </p>
                  </li>
                  <li>
                    <b>11. ELECTRONIC COMMUNICATIONS </b>
                    <p>
                      LockTheLoad will not accept any responsibility for failed, partial or garbled computer
                      transmissions, for any breakdown, failure, connection or availability of computer,
                      telephone, network, electronic or Internet hardware or software, for Internet
                      accessibility or availability or traffic congestion or for any unauthorized human
                      acts, including any errors or mistakes.
                    </p>
                  </li>
                  <li>
                    <b>12. INDEMNIFICATION</b>
                    <p>
                      You agree to defend and indemnify LockTheLoad from and against any claims, causes of
                      action, demands, recoveries, losses, damages, fines, penalties or other costs or
                      expenses of any kind or nature including but not limited to reasonable legal and
                      accounting fees, brought by third parties as a result of:
                    </p>
                    <p>Your breach of this agreement or the documents referenced herein.</p>
                    <p>Your violation of any law or the rights of a third party.</p>
                    <p>Your use of the LockTheLoad platform.</p>
                  </li>
                  <li>
                    <b>13. CHANGES AND TERMINATION </b>
                    <p>
                      We may change the platform and these terms at any time, in our sole discretion and
                      without notice to you. You are responsible for remaining knowledgeable about these
                      terms. Your continued use of the platform constitutes your acceptance of any changes
                      to these terms and any changes will supersede all previous versions of the terms.
                      Unless otherwise specified herein, all changes to these terms apply to all users take
                      effect. Furthermore, we may terminate this agreement with you under these terms at any
                      time by notifying you in writing (including by email) or without any warning.
                    </p>
                  </li>
                  <li>
                    <b>14. INTEGRATION CLAUSE </b>
                    <p>
                      This agreement together with the
                      <a href="/privacy">privacy policy</a> and any other legal notices published by LockTheLoad,
                      shall constitute the entire agreement between you and LockTheLoad concerning and governs
                      your use of the platform.
                    </p>
                  </li>
                  <li>
                    <b>15. DISPUTES</b>
                    <p>
                      The user agrees that any dispute, claim or controversy arising out of or relating to
                      these terms and conditions, or the breach, termination, enforcement, interpretation or
                      validity thereof or the use of the platform, shall be resolved by binding arbitration
                      between the user and LockTheLoad, provided that each party retains the right to bring
                      an individual action in a court of competent jurisdiction.
                    </p>
                    <p>
                      In the event that a dispute arises in connection with the use of the platform or
                      breach of these terms and conditions, the parties agree to submit their dispute to
                      arbitration resolution before a reputable arbitration organization as mutually agreed
                      by the parties and in accordance with applicable commercial arbitration rules.
                    </p>
                    <p>
                      To the fullest extent permitted by law, you agree that you will not file, join or
                      participate in any class action lawsuit in connection with any claim, dispute or
                      controversy that may arise in connection with your use of the platform.
                    </p>
                    <p>
                      The courts of the United States, specifically the courts located in the State of
                      Virginia, shall have jurisdiction over any dispute, controversy or claim relating to
                      LockTheLoad and its business operations. Any such dispute or controversy shall be
                      brought and resolved in the courts of the United States, specifically the courts
                      located in the State of Virginia.
                    </p>
                  </li>
                  <li>
                    <b>16. FINAL PROVISIONS</b>
                    <p>
                      These conditions are governed by international law, specifically the United States
                      law. Use of the LockTheLoad platform is unauthorized in any jurisdiction that does not
                      give effect to all provisions of these terms and conditions.
                    </p>
                    <p>
                      Our performance of these terms is subject to existing laws and legal process, and
                      nothing contained in these terms limits our right to comply with law enforcement or
                      other governmental or legal requests or requirements relating to your use of our
                      platform or information provided to or gathered by us with respect to such use.
                    </p>
                    <p>
                      If any part of these terms is found to be invalid, illegal or unenforceable, the
                      validity, legality and enforceability of the remaining provisions will not in any way
                      be affected or impaired. Our failure or delay in enforcing any provision of these
                      terms at any time does not waive our right to enforce the same or any other
                      provision(s) hereof in the future.
                    </p>
                    <p>Any rights not expressly granted herein are reserved.</p>
                  </li>
                </ul>
              </section>
              <div className='mt-4'></div>
              <section id="refund-policy" className="pt-4">
                <h2 className="font-semibold text-2xl text-primary">Refund Policy - LockTheLoad</h2>
                <p className="text-lg text-secondary pb-4">Published: May 22, 2023</p>
                <div>
                  <ul>
                    <li>
                      <b>1. FREE TRIAL</b>
                      <p>
                        Users will be able to access a free trial before upgrading to a paid subscription.
                        To request a free trial the user must provide an email address. Only one free trial
                        per email will be provided. The user may cancel the trial period at any time before
                        upgrading to a paid subscription.
                      </p>
                    </li>
                    <li>
                      <b>2. REFUNDS</b>
                      <p>
                        Users may request a full refund of the subscription within 7 days of the first
                        charge for the subscription. Refunds will only be issued for the first billing
                        period; no refunds will be issued for subsequent billing periods. To request a
                        refund during the established period, please send us your refund request via email.
                        Please note that if 7 days have passed since the first payment of the subscription
                        no refunds will be issued.
                      </p>
                    </li>
                    <li>
                      <b>3. CANCELLATIONS</b>
                      <p>
                        Subscriptions will automatically renew for an additional period unless cancelled
                        before the next billing date. The user may cancel the subscription at any time and
                        access to the paid features will remain available until the next billing date, when
                        it will be permanently suspended. Subscriptions can be cancelled through the account
                        settings in the billing section. Canceled subscriptions will not be charged on the
                        next billing date and automatic billing will be canceled and stopped..
                      </p>
                    </li>
                  </ul>
                </div>
              </section>
              <div className='mt-4'></div>
              <section id="contact-email">
                <h2 className="font-semibold text-2xl text-primary">Contact</h2>
                <p>
                  If you have questions or concerns about this privacy policy and the treatment and security
                  of your data, please contact us using the contact information below:
                </p>
                <b>Primer IT Solutions Inc - LockTheLoad. </b>
                <p>
                  <a href="mailto:admin@locktheload.com">admin@locktheload.com</a>
                </p>
              </section>
            </div>
            <ul className='scroll_left'>
              <li >
                <p onClick={() => { window.scrollTo(0, 0); }} style={{ color: "blue", cursor: "pointer" }}>
                  Privacy Policy
                </p>
              </li>
              <li>
                <a href="#cookie-policy">
                  Cookie Policy
                </a>
              </li>
              <li>
                <a href="#refund-policy">
                  Refund Policy
                </a>
              </li>
              <li>
                <a href="#terms-conditions">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a href="#contact-email">
                  Contact us
                </a>
              </li>
            </ul>
          </div>

        </Box>
      </div>
    </>
  )
}