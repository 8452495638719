import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { motion, AnimatePresence } from 'framer-motion';
import chrome from "../../assets/new/chrome.png";
import power from "../../assets/new/loadboards/power.png";
import one from "../../assets/new/loadboards/one.png";
import edge from "../../assets/new/loadboards/edge.png";
import Truckpath from "../../assets/new/loadboards/Truckpath.png";
import Trucksmarter from "../../assets/new/loadboards/Trucksmarter.png";
import Truckstop from "../../assets/new/loadboards/Truckstop.png";
import central from "../../assets/new/loadboards/central.png";
import screenshot from "../../assets/new/banner.png";

const loadBoards = [
  { name: 'Power', image: power },
  { name: 'One', image: one },
  { name: 'Edge', image: edge },
  { name: 'Truckpath', image: Truckpath },
  { name: 'Trucksmarter', image: Trucksmarter },
  { name: 'Truckstop', image: Truckstop },
  { name: 'Central', image: central },
];

const UnderlineWave = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 382 16"
    fill="none"
    className="absolute -bottom-1 left-0 w-full h-auto"
    preserveAspectRatio="none"
    style={{
      width: 'calc(100% + 20px)',
      left: '-10px',
      minWidth: '150px',
      maxWidth: '500px',
    }}
  >
    <path
      d="M1 11.4991C70.1667 -2.50094 273 -1.50039 381 14.4987"
      stroke="#0078D4"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const words = [
  { first: "Profit", second: "calculator" },
  { first: "Google", second: "Maps" },
  { first: "Search", second: "filters" },
  { first: "Auto", second: "emails" }
];

const longestText = "Profit calculator"; // Longest text in our words array

const AnimatedWords = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % words.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="inline-block relative">
      {/* Hidden reference text - longest combination */}
      <div
        className="invisible h-0 whitespace-nowrap absolute"
        aria-hidden="true"
        style={{
          minWidth: '320px'
        }}
      >
        {longestText}
      </div>

      {/* Animated text */}
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ rotateX: -90, opacity: 0 }}
          animate={{ rotateX: 0, opacity: 1 }}
          exit={{ rotateX: 90, opacity: 0 }}
          transition={{
            duration: 0.5,
            ease: "easeInOut"
          }}
          className="whitespace-nowrap"
          style={{
            background: 'linear-gradient(96deg, #FE4242 -0.3%, #4B4B4B 29.38%, #29D32E 58.87%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            transformOrigin: "center center"
          }}
        >
          {words[currentIndex].first} {words[currentIndex].second}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};


const Banner = () => {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="relative container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mt-8 sm:mt-12 md:mt-16 lg:mt-24">
          {/* Title Section with Animated Words */}
          <h1 className="relative z-10 text-[32px] sm:text-[48px] md:text-[64px] leading-[1.2] font-[700] mb-4 sm:mb-6">
            <div className="flex flex-col sm:flex-row items-center justify-center sm:space-x-2 space-y-2 sm:space-y-0">
              <div className="w-[320px] sm:w-[420px] md:w-[520px]"> {/* Fixed width container */}
                <AnimatedWords />
              </div>
              <span>Helps Booking</span>
            </div>
            <div className="text-center mt-2">
              Loads in {' '}
              <span className="relative inline-block">
                <span className="text-[#0078D4]">Seconds</span>
                <UnderlineWave />
              </span>
            </div>
          </h1>

          {/* Circle Background */}
          <div
            className="absolute left-1/2 -translate-x-1/2 w-[280px] h-[280px] sm:w-[400px] sm:h-[400px] md:w-[500px] md:h-[500px] lg:w-[600px] lg:h-[600px]"
            style={{
              top: '140px',
              background: 'radial-gradient(50% 50% at 50% 50%, rgba(77, 138, 240, 0.12) 0%, rgba(77, 138, 240, 0.06) 45%, rgba(77, 138, 240, 0) 100%)',
              borderRadius: '50%',
              zIndex: 0,
            }}
          />

          {/* Content Section */}
          <div className="relative z-10 max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <p className="text-base sm:text-lg md:text-xl text-gray-600 mb-6 sm:mb-8 md:mb-10">
              Find better-paying loads, work with trusted brokers, and make smarter decisions easier
            </p>

            <div className="flex justify-center mb-8 sm:mb-10 md:mb-12">
              <motion.a
                href="https://chromewebstore.google.com/detail/locktheload-loads-booking/abfpabcljnjfonkbepfodpdembndjjnk"
                target='_blank'
                rel="noreferrer"
                className="inline-flex items-center px-6 py-3 bg-[#4D8AF0] text-white rounded-lg hover:bg-blue-600 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <img src={chrome} alt="Chrome" className="w-6 h-6 mr-2" />
                Add to Chrome
              </motion.a>
            </div>
          </div>

          {/* Load Boards Logos */}
          <div className="relative z-10 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mb-8 sm:mb-10 md:mb-12">
            <Swiper
              slidesPerView={2}
              spaceBetween={20}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay]}
              breakpoints={{
                480: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 40,
                },
              }}
              className="loadboards-swiper"
            >
              {loadBoards.map((board, index) => (
                <SwiperSlide key={index}>
                  <div className="flex justify-center items-center h-12 sm:h-16 md:h-20">
                    <div className="w-24 sm:w-32 md:w-40 h-8 sm:h-12 md:h-16 relative">
                      <img
                        src={board.image}
                        alt={board.name}
                        className="absolute inset-0 w-full h-full object-contain transition-all duration-300"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Screenshot */}
          <div className="relative z-10 max-w-[1200px] mx-auto">
            <div className="relative h-full w-full rounded-xl overflow-hidden">
              {/* Main image */}
              <div className="relative bg-white rounded-xl overflow-hidden">
                <img
                  src={screenshot}
                  alt="LockTheLoad Dashboard"
                  className="w-full h-auto object-contain rounded-xl"
                />
              </div>

              {/* Border container */}
              <div className="absolute inset-0">
                <div
                  className="absolute border-segment"
                  style={{
                    '--segment-width': '300px',
                    '--duration': '8s'
                  }}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* Responsive styles */}
      <style jsx>{`
      .border-segment {
    width: var(--segment-width);
    height: 2px;
    animation: moveBorder var(--duration) linear infinite;
  }

  .border-segment::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      to right,
      transparent,
      #2C97E3 20%,
      #3355DC 50%,
      #DC3333 80%,
      transparent
    );
  }

  @keyframes moveBorder {
    0% {
      /* Top border, moving right */
      top: 0;
      left: 0;
      transform: translateX(-100%) rotate(0deg);
      width: var(--segment-width);
      height: 2px;
    }
    24.9% {
      /* Top border, reached right */
      top: 0;
      left: 100%;
      transform: translateX(0) rotate(0deg);
      width: var(--segment-width);
      height: 2px;
    }
    25% {
      /* Right border, moving down */
      top: 0;
      right: 0;
      left: auto;
      transform: rotate(90deg);
      transform-origin: right top;
      width: var(--segment-width);
      height: 2px;
    }
    49.9% {
      /* Right border, reached bottom */
      top: 100%;
      right: 0;
      left: auto;
      transform: rotate(90deg);
      transform-origin: right top;
      width: var(--segment-width);
      height: 2px;
    }
    50% {
      /* Bottom border, moving left */
      top: 100%;
      left: 100%;
      transform: translate(0, -2px) rotate(180deg);
      width: var(--segment-width);
      height: 2px;
    }
    74.9% {
      /* Bottom border, reached left */
      top: 100%;
      left: 0;
      transform: translate(0, -2px) rotate(180deg);
      width: var(--segment-width);
      height: 2px;
    }
    75% {
      /* Left border, moving up */
      top: 100%;
      left: 0;
      transform: translate(0, 0) rotate(270deg);
      transform-origin: left top;
      width: var(--segment-width);
      height: 2px;
    }
    99.9% {
      /* Left border, reached top */
      top: 0;
      left: 0;
      transform: translate(0, 0) rotate(270deg);
      transform-origin: left top;
      width: var(--segment-width);
      height: 2px;
    }
    100% {
      /* Back to start */
      top: 0;
      left: 0;
      transform: translateX(-100%) rotate(0deg);
      width: var(--segment-width);
      height: 2px;
    }
  }

      `}</style>
    </div>
  );
};

export default Banner;
