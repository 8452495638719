import React from 'react'
import { X } from 'lucide-react';
import checkIcon from "../assets/new/success.png";


const SuccessModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X size={20} />
        </button>
        <div className="text-center">
          <img
            src={checkIcon}
            alt="Success"
            className="w-30 h-24 mx-auto mb-4"
          />
          <h2 className="text-2xl font-semibold mb-2">Registration Successful</h2>
          <p className="text-gray-600 mb-6 text-sm" style={{
            lineHeight: "24px"
          }}>
            Your email has been successfully registered. You are now entered into the contest!
            Winners are announced at the end of each month, and results will be published.
            Please check your email as we may reach out to you.
          </p>
          <button
            onClick={onClose}
            className="bg-[#4D8AF0] text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal