import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Home } from '../pages/Home'
import { PrivacyPolicy } from '../pages/Privacy/PrivacyPolicy'
import NotFound from '../views/NotFound/NotFound';
import Blog from '../pages/Blog/Blog';
import BlogItem from '../pages/Blog/BlogItem';
import PromotionPage from '../pages/PromotionPage/PromotionPage';
export const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path='/privacy' element={<PrivacyPolicy />} />
      <Route path='/promotion' element={<PromotionPage />} />
      <Route path='/blog' element={<Blog />} />
      <Route path='/blog/:id' element={<BlogItem />} />
      <Route path='/terms-conditions' element={<PrivacyPolicy />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}