import React, { useState, useEffect } from 'react';
import bg from "../../assets/new/banner_2.png"
import SuccessModal from '../../components/SuccessModal';
import { motion } from 'framer-motion';


const CountdownLanding = () => {
  const [countdown, setCountdown] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00'
  });
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    if (email.trim() && email.includes('@') && email.includes('.')) {
      setIsLoading(true);
      try {
        const response = await fetch('https://api-dev.locktheload.com/application', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          setShowModal(true);
          setEmail('');
        }
      } catch (error) {
        console.error('Error submitting email:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    const endDate = new Date('2024-11-31T23:59:59');

    const timer = setInterval(() => {
      const now = new Date();
      const difference = endDate.getTime() - now.getTime();

      if (difference <= 0) {
        clearInterval(timer);
        return;
      }

      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setCountdown({
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0')
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  return (
    <div className="py-24 w-full relative bg-[#225993] bg-cover bg-center bg-no-repeat object-cover"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: '100% 100%'
      }}>
      <div className="absolute inset-0"></div>

      <div className="relative flex flex-col items-center justify-center h-full px-4 text-center text-white">
        <div className="max-w-2xl mx-auto">
          <p className="text-base mb-1 text-white">October</p>
          <h1 className="text-2xl md:text-4xl font-[700] mb-2">
            Win a Free Month of Pro!          </h1>
          <p className="mb-6 text-sm text-white">
            Enter your email for a chance to win a free month of our Pro plan. <br /> Don’t miss this special offer for dispatchers!          </p>
          <motion.div
            className="bg-white/10 backdrop-blur-md rounded-lg p-4 md:p-5 my-10"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <label className="block text-white text-sm mb-2 text-left">Email</label>
            <div className="flex flex-col sm:flex-row gap-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="flex-1 px-4 py-2 rounded-lg bg-white text-gray-900 placeholder-gray-500 text-sm 
                    focus:outline-none focus:ring-2 focus:ring-[#4D8AF0] transition-colors 
                    sm:w-full md:w-3/4 
                    "

              />
              <motion.button
                onClick={handleSubmit}
                disabled={isLoading}
                className="px-6 py-2 bg-[#4D8AF0] text-white rounded-lg hover:bg-blue-600 transition-colors whitespace-nowrap text-sm"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                {isLoading ? 'Sending...' : 'Send email'}
              </motion.button>
            </div>
          </motion.div>

          <div className="flex justify-center items-center gap-1 mb-6 text-xl md:text-2xl">
            <div className="flex gap-1">
              <div className="w-10 h-10 md:w-14 md:h-14 bg-transparent rounded flex items-center justify-center backdrop-blur-sm"
                style={{
                  borderRadius: "8px",
                  border: "1px solid var(--Grey-4, #F6F6F6)"
                }}
              >
                {countdown.hours[0]}
              </div>
              <div className="w-10 h-10 md:w-14 md:h-14 bg-transparent rounded flex items-center justify-center backdrop-blur-sm" style={{
                borderRadius: "8px",
                border: "1px solid var(--Grey-4, #F6F6F6)"
              }}>
                {countdown.hours[1]}
              </div>
            </div>
            <span className="text-xl md:text-2xl px-1">:</span>
            <div className="flex gap-1">
              <div className="w-10 h-10 md:w-14 md:h-14 bg-transparent rounded flex items-center justify-center backdrop-blur-sm" style={{
                borderRadius: "8px",
                border: "1px solid var(--Grey-4, #F6F6F6)"
              }}>
                {countdown.minutes[0]}
              </div>
              <div className="w-10 h-10 md:w-14 md:h-14 bg-transparent rounded flex items-center justify-center backdrop-blur-sm" style={{
                borderRadius: "8px",
                border: "1px solid var(--Grey-4, #F6F6F6)"
              }}>
                {countdown.minutes[1]}
              </div>
            </div>
            <span className="text-xl md:text-2xl px-1">:</span>
            <div className="flex gap-1">
              <div className="w-10 h-10 md:w-14 md:h-14 bg-transparent rounded flex items-center justify-center backdrop-blur-sm" style={{
                borderRadius: "8px",
                border: "1px solid var(--Grey-4, #F6F6F6)"
              }}>
                {countdown.seconds[0]}
              </div>
              <div className="w-10 h-10 md:w-14 md:h-14 bg-transparent rounded flex items-center justify-center backdrop-blur-sm" style={{
                borderRadius: "8px",
                border: "1px solid var(--Grey-4, #F6F6F6)"
              }}>
                {countdown.seconds[1]}
              </div>
            </div>
          </div>

        </div>
      </div>
      {showModal && <SuccessModal onClose={() => setShowModal(false)} />}

    </div>
  );
};

export default CountdownLanding;