import blogs from './blogs.json'
import blog1 from '../../assets/new/blog/blog1.png';
import blog2 from '../../assets/new/blog/blog2.png';
import blog3 from '../../assets/new/blog/blog3.png';
import blog4 from '../../assets/new/blog/blog4.png';


import React from 'react';
import { NavLink } from 'react-router-dom';

const BlogPostCard = ({ title, excerpt, link, image, datePosted }) => {
  return (
    <div className="group flex flex-col md:flex-row gap-8 bg-white rounded-2xl overflow-hidden hover:shadow-xl transition-all duration-300 mb-12 p-4 md:p-6">
      <NavLink
        to={`/blog/${link}`}
        onClick={() => window.scrollTo(0, 0)}
        className="w-full md:w-1/2 overflow-hidden rounded-xl"
      >
        <img
          src={image}
          alt={title}
          className="w-full h-[300px] object-cover rounded-xl transform group-hover:scale-105 transition-transform duration-300"
        />
      </NavLink>

      <div className="w-full md:w-1/2 flex flex-col justify-between">
        <div className="space-y-4">
          <span className="text-blue-600 font-medium text-sm">
            {datePosted}
          </span>

          <NavLink
            to={`/blog/${link}`}
            onClick={() => window.scrollTo(0, 0)}
            className="block group-hover:text-blue-600 transition-colors"
          >
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 line-clamp-2">
              {title}
            </h2>
          </NavLink>

          <p className="text-gray-600 line-clamp-3">
            {excerpt}
          </p>
        </div>

        <NavLink
          to={`/blog/${link}`}
          onClick={() => window.scrollTo(0, 0)}
          className="inline-flex items-center mt-6"
        >
          <button className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center gap-2 group">
            Keep reading
            <svg
              className="w-4 h-4 transform group-hover:translate-x-1 transition-transform"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
          </button>
        </NavLink>
      </div>
    </div>
  );
};

const Blogs = () => {


  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Our Latest Blog Posts
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Discover our latest thoughts, ideas and insights about technology,
            business and innovation.
          </p>
        </div>

        <div className="space-y-8">
          {blogs.map(blog => (
            <BlogPostCard
              key={blog.id}
              image={blog.id === 1 ? blog1 : blog.id === 2 ? blog2 : blog.id === 3 ? blog3 : blog4}
              title={blog.title}
              datePosted={blog.title_bottom}
              excerpt={blog.desc}
              link={blog.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
