import { Box } from "@mui/system";
import Navbar from "./views/Navbar/Navbar.jsx";
import Footer from "./views/Footer/Footer.jsx";
import { useState } from "react";
import { Routers } from "./routes/Routers.jsx";
import { BrowserRouter } from "react-router-dom";
import "./global.css"
function App() {
  return (
    <BrowserRouter>
      <Box>
        <Navbar />
        <Routers />
        <Footer />
      </Box>
    </BrowserRouter>
  );
}

export default App;
