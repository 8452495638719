import React, { useState, useEffect } from 'react';

import winner from "../../assets/new/winner.png";
import win_1 from "../../assets/new/win_1.png";
import win_2 from "../../assets/new/win_2.png";
import win_3 from "../../assets/new/win_3.png";
import table from "../../assets/new/table.png"

const AnimatedCounter = ({ end, duration = 2000 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startTime;
    let animationFrame;

    const animate = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const progress = (currentTime - startTime) / duration;

      if (progress < 1) {
        setCount(Math.floor(end * progress));
        animationFrame = requestAnimationFrame(animate);
      } else {
        setCount(end);
      }
    };

    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration]);

  return <span>{count.toLocaleString()}</span>;
};

const CustomerTestimonials = () => {
  const previousMonth = new Date();
  previousMonth.setMonth(previousMonth.getMonth() - 1);
  const monthName = previousMonth.toLocaleString('en-US', { month: 'long' });

  const stats = [
    {
      icon: win_1,
      count: 1245,
      label: "Users",
      subtext: "Total Participants So Far"
    },
    {
      icon: win_2,
      count: 12,
      label: "Winners",
      subtext: "Total Winners So Far"
    },
    {
      icon: win_3,
      count: 98,
      label: "%",
      subtext: "Registered Personal Email"
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8 md:py-16">
      <div className="text-center mb-8 md:mb-12">
        <div className="flex justify-center mb-4">
          <img
            src={winner}
            alt="Star with laurel"
            className="h-10 w-auto md:h-12"
          />
        </div>
        <h2 className="text-3xl md:text-4xl font-medium text-[#CD9C4B] mb-2">
          {monthName} winners
        </h2>
        <p className="text-gray-600 text-sm md:text-base">
          The winners were determined randomly in the campaign held in {monthName}
        </p>
      </div>

      <div className="max-w-3xl mx-auto mb-8 md:mb-16">
        <img
          src={table}
          alt="Winners Table"
          className="w-full h-auto rounded-lg shadow-sm"
        />
      </div>
      <div className="container mx-auto flex justify-center mt-4">
        <div className=" mx-auto px-4 ">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 lg:gap-12 ">
            {stats.map((stat, index) => (
              <div key={index} className="flex flex-col md:flex-row items-center justify-center md:justify-start text-center md:text-left space-y-2 md:space-y-0 md:space-x-4">
                <div className="flex-shrink-0">
                  <img
                    src={stat.icon}
                    alt={stat.label}
                    className="w-10 h-10 md:w-12 md:h-12 object-contain"
                  />
                </div>
                <div>
                  <div className="text-2xl lg:text-3xl font-[700] text-[#1F1F1F]">
                    <span className="text-2xl lg:text-3xl"><AnimatedCounter end={stat.count} /></span>{' '}
                    <span className="text-lg lg:text-xl text-[#1F1F1F] font-[400]">{stat.label}</span>
                  </div>
                  <p className="text-sm lg:text-base text-[#6B7280] mt-1">
                    {stat.subtext}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTestimonials;