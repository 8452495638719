import React, { useState } from 'react';
import { Facebook, Instagram, } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from "../../assets/new/logo.png";
import SuccessModal from '../../components/SuccessModal';



const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleNavigation = (target) => {
    if (location.pathname !== '/') {
      navigate(`/?section=${target}`);
    } else {
      const element = document.getElementById(target);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleSubmit = async () => {
    if (email.trim() && email.includes('@') && email.includes('.')) {
      setIsLoading(true);
      try {
        const response = await fetch('https://api-dev.locktheload.com/application', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          setShowModal(true);
          setEmail('');
        }
      } catch (error) {
        console.error('Error submitting email:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <footer className="bg-white py-12 border-t relative">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-start">
          {/* Left Section */}
          <div className="mb-6 md:mb-0">
            <div className="mb-6">
              <img src={logo} alt="Lock the Load" className="h-8" />
            </div>
            <div className="flex space-x-4 mb-4">
              <a
                href="https://www.facebook.com/locktheload"
                target="_blank"
                rel="noreferrer"
                className="bg-blue-600 w-8 h-8 flex items-center justify-center rounded"
              >
                <Facebook size={16} className="text-white" />
              </a>
              <a
                href="https://www.instagram.com/thelocktheload"
                target="_blank"
                rel="noreferrer"
                className="bg-pink-600 w-8 h-8 flex items-center justify-center rounded"
              >
                <Instagram size={16} className="text-white" />
              </a>
              <a
                href="https://t.me/locktheload"
                target="_blank"
                rel="noreferrer"
                className="bg-[#2DAEF1] w-8 h-8 flex items-center justify-center rounded"
              >
                <svg width="26px" height="26px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="16" cy="16" r="14" fill="url(#paint0_linear_87_7225)" />
                  <path d="M22.9866 10.2088C23.1112 9.40332 22.3454 8.76755 21.6292 9.082L7.36482 15.3448C6.85123 15.5703 6.8888 16.3483 7.42147 16.5179L10.3631 17.4547C10.9246 17.6335 11.5325 17.541 12.0228 17.2023L18.655 12.6203C18.855 12.4821 19.073 12.7665 18.9021 12.9426L14.1281 17.8646C13.665 18.3421 13.7569 19.1512 14.314 19.5005L19.659 22.8523C20.2585 23.2282 21.0297 22.8506 21.1418 22.1261L22.9866 10.2088Z" fill="white" />
                  <defs>
                    <linearGradient id="paint0_linear_87_7225" x1="16" y1="2" x2="16" y2="30" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#37BBFE" />
                      <stop offset="1" stopColor="#007DBB" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
            </div>
            <p className="text-sm text-gray-500">© 2024 LocktheLoad. All Rights Reserved.</p>
          </div>

          {/* Middle Navigation */}
          <div className="grid grid-cols-2 gap-x-16 gap-y-4 mb-6 md:mb-0">
            <div>
              <button
                onClick={() => handleNavigation('features')}
                className="text-gray-600 hover:text-gray-900"
              >
                Features
              </button>
            </div>
            <div>
              <button
                onClick={() => handleNavigation('faq')}
                className="text-gray-600 hover:text-gray-900"
              >
                F.A.Q
              </button>
            </div>
            <div>
              <button
                onClick={() => handleNavigation('plan')}
                className="text-gray-600 hover:text-gray-900"
              >
                Plans
              </button>
            </div>
            <div>
              <a
                href="/privacy"
                className="text-gray-600 hover:text-gray-900"
              >
                Terms of condition
              </a>
            </div>
            <div>
              <button
                onClick={() => handleNavigation('reviews')}
                className="text-gray-600 hover:text-gray-900"
              >
                Reviews
              </button>
            </div>
            <div>
              <a
                href="/privacy"
                className="text-gray-600 hover:text-gray-900"
              >
                Privacy Policy
              </a>
            </div>
          </div>

          {/* Right Section */}
          <div className="w-full md:w-auto">
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Still have questions?</h3>
              <p className="text-sm text-gray-600">Leave your email, we will contact you ourselves</p>
            </div>
            <div className="flex gap-2">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-4 py-2 border rounded-[8px] w-full md:w-64 focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isLoading}
              />
              <button
                onClick={handleSubmit}
                className="bg-[#4D8AF0] text-white px-4 py-2 rounded-[8px] hover:bg-blue-600 transition-colors disabled:opacity-50"
                disabled={isLoading}
              >
                {isLoading ? 'Sending...' : 'Send email'}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      {showModal && <SuccessModal onClose={() => setShowModal(false)} />}
    </footer>
  );
};

export default Footer;