import { Box } from '@mui/material'
import React, { Suspense } from 'react'
import Banner from '../views/Banner/Banner'
const Features = React.lazy(() => import('../views/Features/Features'));
const About = React.lazy(() => import('../views/About/About'));
const Install = React.lazy(() => import('../views/Install/Install'));
const Faq = React.lazy(() => import('../views/Faq/Faq'));
const Loadboards = React.lazy(() => import('../views/Loadboard/Loadboards'));
const Blogs = React.lazy(() => import('../views/Blogs/Blogs'));
const Price = React.lazy(() => import('../views/Price/Price'));
const Products = React.lazy(() => import('../views/Producs/Products'));
const Services = React.lazy(() => import('../views/Emails/Services'));
const PromoCard = React.lazy(() => import('../views/Promotion/PromoCard'));
const Reviews = React.lazy(() => import('../views/Review/Reviews'));
export const Home = () => {
  return (
    <Box className="bg-[#fff]">
      <Banner />
      <Suspense > <Loadboards /></Suspense>
      <Suspense> <Features /></Suspense>
      <Suspense > <Services /></Suspense>
      <Suspense > <PromoCard /></Suspense>
      <Suspense > <Reviews /></Suspense>
      <Suspense > <Price /></Suspense>
      <Suspense > <About /></Suspense>
      <Suspense > <Products /></Suspense>
      <Suspense > <Faq /></Suspense>
      <Suspense > <Blogs /></Suspense>
      <Suspense > <Install /></Suspense>

    </Box>
  )
}