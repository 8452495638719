import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ChevronDown } from 'lucide-react';

const AccordionItem = ({ title, content, isOpen, onToggle }) => {
  return (
    <div className="border-b border-gray-200 last:border-b-0"
    >
      <button
        className="w-full py-6 text-left flex justify-between items-center transition-all duration-300 ease-in-out group"
        onClick={onToggle}
      >
        <span className="font-medium text-lg text-gray-900">{title}</span>
        <ChevronDown
          className={`text-gray-400 w-6 h-6 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames={{
          enter: 'opacity-0',
          enterActive: 'opacity-100 transition-opacity duration-300 ease-in-out',
          exit: 'opacity-100',
          exitActive: 'opacity-0 transition-opacity duration-300 ease-in-out',
        }}
        unmountOnExit
      >
        <div className="pb-6 pt-2">
          <p className="text-gray-600">{content}</p>
        </div>
      </CSSTransition>
    </div>
  );
};

const FAQ = () => {
  const [openItem, setOpenItem] = useState(0);

  const faqItems = [
    {
      title: "What is LocktheLoad?",
      content: "LocktheLoad is a powerful extension designed for Amazon Relay users. It helps you filter loads based on time, money, distance, and other key factors, making load management faster and more efficient. It also includes features like load copying, truck posting, and Google Maps integration for better route visualization."
    },
    {
      title: "How does the load filtering work?",
      content: "Our load filtering system allows you to set specific criteria for the loads you want to see. You can filter by rate, distance, pickup/delivery times, and locations. The system automatically shows only the loads that match your preferences, saving you time in load selection."
    },
    {
      title: "How do I post my trucks with LocktheLoad?",
      content: "Posting your trucks with LocktheLoad is simple. Navigate to the truck posting section, enter your truck details and availability, and the system will automatically post them at your specified times. You can also set up recurring posts for regular availability."
    },
    {
      title: "How do I view load routes on a map?",
      content: "With our Google Maps integration, you can easily visualize load routes. Click on any load to see the pickup and delivery points on the map, along with the suggested route. This helps you better plan your trips and estimate actual driving distances."
    }
  ];

  return (
    <section id='faq' className="bg-white">
      <div className="max-w-5xl mx-auto py-16 px-4 sm:px-6">
        <span className="text-[#4D8AF0] text-lg mb-4 block text-center">FAQ</span>
        <h2 className="text-3xl sm:text-4xl font-medium text-gray-900 text-center mb-12">
          Frequently Asked Questions
        </h2>
        <div className="divide-y divide-gray-200  border-b border-gray-200">
          {faqItems.map((item, index) => (
            <AccordionItem
              key={index}
              title={item.title}
              content={item.content}
              isOpen={openItem === index}
              onToggle={() => setOpenItem(openItem === index ? -1 : index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;